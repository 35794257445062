import * as React from "react"

import Layout from '../components/Layout';

const PriivacyPolicyPage: React.FC = () => {
  return (
    <Layout title="Privacy Policy">
      <p><strong>SHOPMOJO PRIVACY POLICY</strong></p>
      <p>(Last Updated and Effective: Apr 10, 2019)</p>
      <p>
        <strong>ShopMojo.app</strong>&nbsp;(“ShopMojo,” “we,” “our,” or “us”) provides
        this Privacy Policy to inform you of our policies and procedures regarding the
        collection, use and disclosure of information we receive from users of our
        website and services (collectively, the “Services”). We collect and use
        information from visitors to our website. We also collect, maintain, and
        process information submitted by our Clients using our Services. We provide
        Clients with business to business data analysis regarding our Client’s
        customers and visitors to our Client’s websites (“Customer Data”). If you
        believe we maintain your personal information, this Privacy Policy describes
        your options for accessing, limiting the use and disclosure of, and deletion
        of your personal information.
      </p>
      <p>
        We understand that you value your privacy, and we want to help make your
        experience with our Services satisfying and safe. This Privacy Policy may be
        updated from time to time. For example, we may update this Privacy Policy to
        reflect changes to our information collection and use practices. If we make
        any material changes we will notify you by email (sent to the email address
        specified in your account, if any) or by posting the revised policy on this
        web page prior to the change becoming effective. We encourage you to
        periodically review this page for the latest information on our privacy
        practices. Unless otherwise defined in this Privacy Policy, terms used in this
        Privacy Policy have the same meanings as in our Terms of Service.
      </p>
      <h3><strong>Categories of Information We Collect</strong></h3>
      <h4><strong>Information You Provide to Us</strong></h4>
      <p>
        We collect certain information you provide directly to us when using the
        Services, such as when you send a communication to us or submit a Subscription
        Order. The information we collect may include your personal and/or company
        name, telephone number, email address, postal address, payment information and
        other information you choose to provide.
      </p>
      <h4><strong>Information Collected Automatically</strong></h4>
      <p>
        Aside from the information that you provide us, we also automatically collect
        certain information when you use the Services or visit one of our Client’s
        websites. This information includes the following:
      </p>
      <ul>
        <li>
          <span>
            Device Information: We collect certain information about the devices you
            use with the Services, including such data as unique device identifiers,
            device make and model, device name, push notification identifiers, MAC
            address, Internet Protocol (IP) address, carrier name, operating system
            and browser types (collectively, “Device Information”).
          </span>
        </li>
        <li>
          Usage Information: We collect information about how people use the Services.
          This type of information may be collected in the log files of our servers or
          in our analytics systems each time you interact with the Services. We may
          use third party services, businesses, websites, and applications
          (collectively, “Third Party Services”), in addition to internal tools to,
          among other uses, collect and analyze this information. This data may
          include Device Information, IP address, and cookie information.
        </li>
        <li>
          Links: We may keep track of how you interact with links across our Services
          by redirecting links or through other means.
        </li>
        <li>
          Location Information: We may also automatically collect your physical
          location if you choose to enable your computer or mobile device to send us
          location information.
        </li>
        <li>
          Cookies and Other Tracking Information: Like many websites, ShopMojo and Third
          Party Services may collect certain information through the use of “cookies.”
          These are small files that your browser places on your computer which, among
          other uses, allow you to sign in to your Account in a browser and maintain
          an identified browsing session with ShopMojo. Please refer to the “Your Choices;
          Cookies” section below to learn more about disabling cookies.
        </li>
      </ul>
      <h4><strong>Use of Information</strong></h4>
      <p>
        We use the information collected through the Services for the following
        purposes:
      </p>
      <ul>
        <li>
          To process requests or transactions you initiate by accessing or using the
          Services;
        </li>
        <li>
          To monitor and analyze usage and trends in order to improve the Services, or
          to improve your experience with the Services, for example by providing
          personalized content, features, or advertisements;
        </li>
        <li>To verify your identity, in order to protect your privacy;</li>
        <li>
          To notify you of security alerts, important updates to our Terms of Service
          and Use or this Privacy Policy;
        </li>
        <li>To update you about activity on your account, or news about ShopMojo;</li>
        <li>
          To reach out to you about customer service issues and respond to your
          comments, questions, and requests;
        </li>
        <li>
          To link or combine with information we get from others to help understand
          your needs or provide you with better service;
        </li>
        <li>
          To detect, investigate, and/or prevent fraudulent, unauthorized, or illegal
          activity; and
        </li>
        <li>
          For any other purpose for which the information was originally collected.
        </li>
      </ul>
      <h4><strong>Sharing of Information</strong></h4>
      <p>
        Aside from what is otherwise described in this Privacy Policy, we will only
        share information about you as follows:
      </p>
      <ul>
        <li>As necessary to provide the Services to you;</li>
        <li>
          With Third Party Services, consultants, or others who are working with us
          and need access to such information to carry out work on our behalf;
        </li>
        <li>
          In response to legal process or a request for information if we believe
          disclosure is in accordance with any applicable law, rule, or regulation, or
          as otherwise required by any applicable law, rule or regulation;
        </li>
        <li>
          In order to investigate or remedy potential violations of our user
          agreements or policies, or to protect the rights, property and safety of
          ShopMojo, our users, or others;
        </li>
        <li>
          In connection with, or during negotiations of, any merger, sale of company
          assets, financing, or acquisition of all or a portion of our business to
          another company; and
        </li>
        <li>
          When you give us consent to do so, including if we notify you that the
          information you provide will be shared in a particular manner and you
          provide such information.
        </li>
      </ul>
      <p>
        ShopMojo uses a variety of Third Party Services to help provide our Services, such
        as hosting our servers, websites, providing analytics, and searching log
        files. Although we try to work only with Third Party Services that share our
        respect for your privacy, unless required by applicable law, we do not take
        responsibility for the actions, content, or privacy policies of those Third
        Party Services&nbsp;. We are not responsible for any additional information
        you provide directly to Third Party Services. Some of these Third Party
        Services, such as analytics providers, may collect information about your
        online activities over time and across different websites, applications, or
        other online services when you use our Services.
      </p>
      <h4><strong>Protecting and Securing Your Information Online</strong></h4>
      <p>
        We take reasonable measures to ensure that your personal information is
        transmitted and stored securely, in order to prevent loss, misuse, and
        unauthorized access, destruction, or disclosure. However, please be aware
        that, despite our efforts, no security measures are perfect or impenetrable,
        and no method of data transmission can be guaranteed against any interception
        or other type of misuse.
      </p>
      <h4><strong>Our Policy Towards Children</strong></h4>
      <p>
        Our service is not directed at individuals under the age of 18 years old, nor
        do we knowingly collect personal information from children under 16. If you
        become aware that your child has provided us with personal information without
        your consent, please contact us at support@shopmojo.app
      </p>
      <h4><strong>International Users</strong></h4>
      <p>
        ShopMojo’s Services are hosted in the United States and may only be used by end
        users lawfully residing in the United States. If you are accessing the
        Services from any other region with laws or regulations governing personal
        data collection, use, and disclosure that differ from those of the United
        States, be advised that through your continued use of the Services, which are
        governed by U.S. law, you are transferring your personal information to the
        United States and you consent to that transfer.
      </p>
      <h3><strong>EU Data Subject Rights</strong></h3>
      <h4><strong>Lawful Grounds</strong></h4>
      <p>
        If you reside in the European Economic Area or Switzerland (collectively the
        “EU”), we rely on the following lawful grounds under the General Data
        Protection Regulation (“GDPR”) to process (collect, store, and use) your
        personal data: (a) it is necessary for the performance of a contract; (b) our
        or a third party’s legitimate business interest; or (c) your consent. Our
        Clients determine the lawful grounds for the processing of personal data
        submitted through their use of our Services and we limit such processing to
        our Clients’ instructions.
      </p>
      <h4><strong>Data Transfer Notice</strong></h4>
      <p>
        We are located in the United States. We use model contractual clauses with our
        Clients to provide adequate protection for the transfer and processing of your
        personal data in the United States.
      </p>
      <h4><strong>Individual Rights</strong></h4>
      <p>
        We provide tools to our Clients to respond directly to data subject requests
        to access, transfer, rectify or erase their personal data, or to restrict or
        object to the processing of their personal data.
      </p>
      <p>
        You may also contact us at&nbsp;<a href="mailto:support@shopmojo.app">support@shopmojo.app</a
        >&nbsp;to request access to, transfer of, and rectification or erasure of your
        personal data, or restriction of processing, or to object to processing of
        your personal data. Please specify the nature of your request and the
        information that is the subject of your request. We may require you to submit
        additional information necessary to verify your identity and status as an EU
        data subject. If we are processing your personal data for a client, we will
        forward your request to our client and assist the client in responding within
        30 days. If you are a visitor or user of our Services, we will respond to your
        request directly within 30 days.
      </p>
      <p>
        If we are processing your personal data based upon the lawful ground of your
        consent, you have the right to withdraw your consent for such processing at
        anytime without affecting the lawfulness of processing based on consent before
        it is withdrawn. To withdraw consent, email us at&nbsp;
        <a href="mailto:support@shopmojo.app" >support@shopmojo.app</a>.
      </p>
      <h4><strong>Retention</strong></h4>
      <p>
        At a minimum, we will retain your personal data for a long as necessary for
        the purpose in which it was collected such as to perform a contract, for our
        or a third party’s legitimate interest, or your consent. Unless you request
        deletion of your personal data sooner, we may hold your personal data for 365
        days after a client stops using our Services.
      </p>
      <h4><strong>Your Choices; Cookies</strong></h4>
      <ul>
        <li>
          Submission of Personal Information. You may, of course, decline to submit
          information through the Services, but we may not be able to provide all or a
          portion of the Services to you.
        </li>
        <li>
          Cookies. You have the ability to accept or decline cookies. Most Web
          browsers automatically accept cookies, but you can usually modify your
          browser setting to decline cookies in the browser settings if you prefer. If
          you choose to decline cookies, you may not be able to sign in or use certain
          Services and/or Third Party Services features that depend on cookies.<br />•
          Contact Us. If you receive commercial e-mail from us, you may unsubscribe at
          any time by following the instructions contained within the e-mail. You may
          also opt-out from receiving commercial e-mail from us, and any other
          promotional communications that we may send to you from time to time by
          sending your request to us by e-mail at support@shopmojo.app
        </li>
      </ul>

    </Layout>
  )
}

export default PriivacyPolicyPage
