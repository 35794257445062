import React from 'react';
import Helmet from 'react-helmet';

import { Link } from "gatsby";

import Container from '../Container'
import Footer from '../Footer'

import * as styles from './Layout.module.scss';

interface Props {
  title: string;
}

const Layout: React.FC<Props> = ({ children, title }) => {
  return (
    <div className={styles.layout}>
      <Helmet>
        <title>{title} - ShopMojo</title>
      </Helmet>
      <Container>
        <div className={styles.topBar}>
          <Link to="/">
            <img src="/logo.png" className={styles.logo} />
          </Link>
          {/* <div className={styles.menu}>
            <a href="#">Contact Us</a>
          </div> */}
        </div>
      </Container>
      <Container className={styles.pageContent}>
        {children}
      </Container>
      <Container>
        <Footer />
      </Container>
    </div>
  );
}

export default Layout;