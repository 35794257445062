import React from 'react';
import clsx from 'clsx';
import * as styles from './Container.module.scss';


interface Props {
  className?: string;
}
const Container: React.FC<Props> = ({ children, className }) => {

  return (
    <div className={clsx(styles.container, className)}>
      {children}
    </div>
  );
}

export default Container;